<template>
  <div>
    <div class="locale">
      <h2><a-button @click="$router.back()" shape="circle" icon="arrow-left"/>添加榜单</h2>
      <ol>
        <li><router-link to="/">后台</router-link></li>
        <li><a href="javascript:;">测验</a></li>
        <li><a href="javascript:;">榜单</a></li>
        <li>添加</li>
      </ol>
    </div>

        <a-form-model ref="form" :rules="form.rules" :model="form.data" v-bind="form.layout" @submit="onSubmit" @submit.native.prevent>
          <a-card title="榜单信息">

            <!-- <a-divider orientation="left">个人信息</a-divider> -->


            <a-form-model-item prop="image" label="主图片">
              <van-uploader multiple :max-count="1" v-model="form.images" />
            </a-form-model-item>



            <a-form-model-item prop="name" label="名称">
              <a-input ref="name" type="text" placeholder="榜单名称" v-model="form.data.name" />
            </a-form-model-item>

            <a-form-model-item prop="number" label="最多人数">
              <a-input type="number" placeholder="最多入围人数" v-model="form.data.number" />
            </a-form-model-item>

            <a-form-model-item prop="bonus" label="默认奖金">
              <a-input type="text" placeholder="每人默认奖金" v-model="form.data.bonus" />
            </a-form-model-item>
          

            <a-form-model-item prop="end" label="时间范围">
              
                <a-date-picker v-model="form.data.begin" placeholder="选择开始时间" />
                  <a-divider type="vertical"/>
                  <a-date-picker v-model="form.data.end" placeholder="选择结束时间" />

            </a-form-model-item>

            <a-form-model-item prop="remark" label="备注">
              <a-textarea rows="2"  placeholder="备注" v-model="form.data.remark" />
            </a-form-model-item>

          </a-card>

          <div class="submit">
            <a-button type="primary" html-type="submit">确认添加</a-button>
          </div>

        </a-form-model>
  </div>
</template>

<script>
  export default {
    name: 'TestRoleCreate',
    data () {
      return {

        form: {
          layout: {
            labelCol: { span: 6 },
            wrapperCol: { span: 10 },
          },
          rules: {
            name: [{ required: true, message: '名称不可为空' }],
            number: [{ required: true, message: '人数不可为空' }],
            bonus: [{ required: true, message: '默认奖金不可为空' }],
            begin: [{ required: true, message: '时间范围不可为空' }],
            end: [{ required: true, message: '时间范围不可为空' }],
          },
          images: [], backgrounds: [],
          data: {
            name: '', number: '', bonus: '', begin: '', end: '', remark: ''
          }
        }
      }
    },
    mounted() {
      this.$get('/test/rank/create').then( res => {
        let ajax = res.data;
        if (ajax.code == 0) {
        }
      })
    },

    methods: {

      onSubmit(){
        this.$refs['form'].validate(valid => {
          if (valid) {
            this.create();
          }
        });
      },
      create (){
        let form = new FormData();
        if (this.form.images.length) {
          form.append("image", this.form.images[0].file);
        }
        

        for (let k in this.form.data) {
          let v = this.form.data[k];
          if (v.format) {
            form.append(k, v.format('YYYY-MM-DD 00:00:00'))
          } else {
            form.append(k, this.form.data[k])
          }
        }

        this.$post('/test/rank/create', form).then(res => {
          let ajax = res.data;
          if (ajax.code == 0) {
            setTimeout(()=>{
              this.$router.replace('/test/rank/award/'+ajax.data.item.id);
            }, 500);

          }
        })

      }
    }
  }
</script>
<style lang="less" scoped>
  .profile {
  }
</style>
